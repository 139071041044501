import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BRAND, GENERAL_URL} from "@prcins/constants";
import {EsignService} from "../services/esign.service";

@Component({
  selector: 'prcins-esign',
  templateUrl: './esign.component.html',
  styleUrls: ['./esign.component.css']
})
export class EsignComponent implements OnInit {
  @Input() eligibleForms;
  @Output() eSignCompleted = new EventEmitter();
  @Output() eSignSkipped = new EventEmitter();
  @ViewChild('signature', {static: false}) signature;
  showSpinner = false;
  currentForm;
  currDateTime = new Date();
  brand = localStorage.getItem(BRAND);
  showSkipESignPopup = false;
  showSignatureError = false;
  currentFormIndex = 0;
  faqUrl = GENERAL_URL.resource;
  glossaryUrl = GENERAL_URL.glossary;
  bgUrl = GENERAL_URL.buyersGuide;
  brUrl = GENERAL_URL.billOfRights;
  lobHome = false;

  constructor(
    private eSignService: EsignService
  ) {
  }

  ngOnInit() {
    if (this.eligibleForms && this.eligibleForms.length) {
      this.currentForm = this.eligibleForms[this.currentFormIndex];
      this.currentForm.fullName = this.currentForm.firstName + ' ' + this.currentForm.lastName;
      this.currentForm.fullName = this.currentForm.fullName.toUpperCase();
      this.lobHome = this.currentForm.variance.lob === 'HO';
    }
  }

  generateDocURL(form: any) {
    this.eSignService.viewDoc(this.currentForm.policyNumber, form.esignId, form.formId, this.currentForm.token)
      .subscribe(data => {
        const linkSource = 'data:application/pdf;base64,' + data;
        const link = document.createElement("a");
        link.href = linkSource;
        link.download = `${form.formDesc}.pdf`
        link.click();
      }, error => {
        console.error(error);
        this.showSkipESignPopup = true;
      });
  }

  skipESign() {
    this.eSignSkipped.emit();
  }

  submitESign(signature) {
    this.showSignatureError = false;
    this.showSpinner = true;
    if (this.currentForm.fullName !== signature.toUpperCase()) {
      this.showSignatureError = true;
      return;
    }

    this.eSignService.submitESign(this.currentForm).subscribe(data => {
      if ((this.currentFormIndex+1) < this.eligibleForms.length) {
        this.currentFormIndex++;
        this.currentForm = this.eligibleForms[this.currentFormIndex];
        this.currentForm.fullName = this.currentForm.firstName + ' ' + this.currentForm.lastName;
        this.currentForm.fullName = this.currentForm.fullName.toUpperCase();
        this.signature.nativeElement.value = '';
        this.showSpinner = false;
        window.scroll(0,0);
      } else {
        this.eSignCompleted.emit();
        this.showSpinner = false;
      }
    });

  }
}


