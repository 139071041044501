import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BankFormProviderService,
  CardFormProviderService,
  FormValidatorService,
  DiscardComponent,
  INotification,
  paymentDetailsValidatorFn,
  RegistrationService
} from '@prcins/utils';
import {
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';
import {
  CURRENT_POLICY,
  QPAY_PAYMENT_DETAILS,
  USER_ID,
  CURRENT_POLICY_LOB,
  CURRENT_POLICY_PRODUCT_CODE,
  WALLET_APP_NAME,
  PAYMENT_SOURCE,
  ADMIN_QPAY_TOKEN,
  SCHEDULED_PAY_PLANS,
  CURRENT_POLICY_PAY_PLAN,
  QPAY_REGISTRATION_EMAIL
} from '@prcins/constants';
import { PaymentProviderService } from '../services/payment-provider.service';
import { switchMap, tap, filter } from 'rxjs/operators';
import { of, iif, merge, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { ScrollToInvalidService } from '../services/scroll-to-invalid.service';
import { RightRailService } from '../../../../utils/src/lib/services/right-rail.service';

@Component({
  selector: 'prcins-payment-entry-form',
  templateUrl: './payment-entry-form.component.html',
  styleUrls: ['./payment-entry-form.component.css']
})
export class PaymentEntryFormComponent extends DiscardComponent implements OnInit {
  @ViewChild('paymentFormRef', { static: false })
  paymentFormRef: any;
  private hydrateFormSubject$ = new BehaviorSubject<boolean>(false);
  hydrateFormObservable = this.hydrateFormSubject$.asObservable();
  paymentMethod = {
    eft: false,
    cc: false
  };
  payInFullFlag: boolean;
  policyNumber;
  submitted = false;

  paymentForm = this.fb.group({
    confirmationEmail: [''],
    paymentAmountType: ['%%', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\-_]*$/)]],
    paymentAmount: [
      '',
      [
        Validators.required,
        Validators.min(0.01),
        Validators.max(10000),
        this.paymentAmountValidator()
      ]
    ],
    paymentOptionSelected: ['', Validators.required],
    ccSavedPaymentMethod: [''],
    eftSavedPaymentMethod: [''],
    savedCardSecurityCode: ['', Validators.required],
    saveThisBank: [''],
    saveThisCard: [''],
    eftForm: this.bankFormProvider.getForm(),
    cardForm: this.cardFormProvider.getForm()
  }, { validators: [paymentDetailsValidatorFn()] });
  billingDetails;
  policyDetails;
  addressDetails;
  underwriterCode = "ALN_UND";
  balanceDue;
  coverageThrough;
  isPaymentDetailsLoaded = false;
  isReviewInProgress = false;
  invalidCard = false;
  cardNotification: INotification;
  showPaymentImpMsg = false;
  addNewCard = false;
  addNewBank = false;
  showSpinner = false;
  paymentDetails: any;
  featureFlags = [];
  isWalletFlagEnabled = false;

  helps = {
    paymentAmount: false,
    savedCardSecurityCode: false
  }

  helpFlags = { ... this.helps };

  validCardEntry$ = of(this.paymentForm).pipe(
    switchMap(form =>
      this.paymentProvider.encryptPaymentDetails(
        form.value,
        this.policyDetails.policyNumber
      )
    ),
    tap(encrypt => this.savePdAndNavigate(encrypt))
  );

  invalidCardEntry$ = of({}).pipe(
    tap(() => {
      this.invalidCard = true;
      this.cardNotification = { type: 'ERROR', message: 'Invalid Credit / Debit card information.' };
    }));

  cardEntry$ = of(this.paymentForm).pipe(
    filter(form => form.valid && form.value.paymentOptionSelected === 'cc' && this.addNewCard),
    switchMap((form: any) => {
      const {
        policyNumber,
        companyCode,
        productCode: productCd,
        branchCode: branchCode
      } = this.policyDetails;
      const { state: policyState, zipCode: policyZip } = this.addressDetails;
      return this.paymentProvider.validateCard(
        {
          policyNumber,
          policyState,
          uwCompany: companyCode,
          productCd,
          policyZip,
          cardNumber: form.value.cardForm.cardNumber,
          branchCd: branchCode
        },
        //this.underwriterCode
        "ALN_UND"
      );
    }),
    switchMap((validateResp: any) =>
      iif(
        () => validateResp.valid === false,
        this.invalidCardEntry$,
        this.validCardEntry$
      )
    )
  );

  savedCardEntry$ = of(this.paymentForm).pipe(
    filter(() => this.paymentForm.value.paymentOptionSelected === 'cc' && !this.addNewCard),
    tap(form => {
      form.controls.cardForm.enable();
      this.fillUserInformation(this.billingDetails.insuredName);
    }),
    tap(form => form.value.savedPaymentMethod = this.storedWalletMap[form.value.ccSavedPaymentMethod]),
    switchMap(form =>
      this.paymentProvider.encryptPaymentDetails(
        form.value,
        this.policyDetails.policyNumber
      )
    ),
    tap(encrypt => this.savePdAndNavigate(encrypt))
  );

  electronicEntry$ = of(this.paymentForm).pipe(
    filter(form => form.valid && form.value.paymentOptionSelected === 'eft' && this.addNewBank),
    switchMap(form =>
      this.paymentProvider.encryptPaymentDetails(
        form.value,
        this.policyDetails.policyNumber
      )
    ),
    tap(encrypt => this.savePdAndNavigate(encrypt))
  );

  savedBankEntry$ = of(this.paymentForm).pipe(
    filter(() => this.paymentForm.value.paymentOptionSelected === 'eft' && !this.addNewBank),
    tap(form => {
      form.controls.eftForm.enable();
      this.fillUserInformation(this.billingDetails.insuredName);
    }),
    tap(form => form.value.savedPaymentMethod = this.storedWalletMap[form.value.eftSavedPaymentMethod]),
    switchMap(form =>
      this.paymentProvider.encryptPaymentDetails(
        form.value,
        this.policyDetails.policyNumber
      )
    ),
    tap(encrypt => { this.savePdAndNavigate(encrypt) })
  );

  invalidEntry$ = of(this.paymentForm).pipe(
    filter(form => (this.addNewBank || this.addNewCard) && !form.valid),
    tap(form => form.markAllAsTouched())
  );

  storedCreditCards = [];
  storedBankAccounts = [];
  storedWalletMap: {};
  paymentSavingAllowed: boolean;
  cvvLength: number;
  areYouSurePopup: boolean;
  showNote: boolean;
  paymentD: any;
  disableEFT: boolean = false;
  isDeferedEserviceRegisteration = false;
  showIntermediateRegisterationForm = false;
  defaultCreditCardWallet = null;
  defaultBankAccountWallet = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bankFormProvider: BankFormProviderService,
    private cardFormProvider: CardFormProviderService,
    private paymentProvider: PaymentProviderService,
    private formValidator: FormValidatorService,
    private managePaymentsProviderService: ManagePaymentsProviderService,
    private scrollToInvalidService: ScrollToInvalidService,
    private registerationService: RegistrationService,
    private rightRailServiceProvider: RightRailService
  ) {
    super();
    this.route.queryParams.subscribe((params) => {
      let isGuestQpayLogin = sessionStorage.getItem(PAYMENT_SOURCE) ? sessionStorage.getItem(PAYMENT_SOURCE) === 'qpay' : false;
      if (isGuestQpayLogin) {
        let policyNumber = sessionStorage.getItem(CURRENT_POLICY);
        this.registerationService.checkIfPolicyAlreadyRegisteredInEservice(policyNumber).subscribe((resp) => {
          if (resp) {
            this.showIntermediateRegisterationForm = false;
          } else {
            this.isDeferedEserviceRegisteration = params['enroll'] ? params['enroll'] === 'defer' : false;
            if (this.isDeferedEserviceRegisteration) {
              this.showIntermediateRegisterationForm = false;
              sessionStorage.setItem("qPayEserviceEnroll", "defer");
            } else {
              this.showIntermediateRegisterationForm = true;
              sessionStorage.removeItem("qPayEserviceEnroll");
            }
            sessionStorage.removeItem(QPAY_REGISTRATION_EMAIL);
          }

        });

      }
    })
  }

  ngOnInit() {

    if (sessionStorage.getItem('qPayEmail')) {
      this.paymentForm.patchValue({ 'confirmationEmail': sessionStorage.getItem('qPayEmail') })

    } else if (sessionStorage.getItem('userId')) {
      this.paymentForm.patchValue({ 'confirmationEmail': sessionStorage.getItem('userId') })
    }

    this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
 
    this.rightRailServiceProvider.getFlagsFromCache(this.policyNumber).subscribe(data => {
      this.isWalletFlagEnabled = data;
    });

    this.deactivateGuardSelection$.subscribe(selection => {
      if (selection) {
        this.paymentProvider.resetACHFailureCount();
      }
    });
    this.showSpinner = true;
    this.setupPaymentForms();

    let validStatus = ["Cancel", "Canceled", "NonRenewed"];

    this.route.queryParams.subscribe(params => {
      const { m: mode, amt: amount } = params;
      switch (mode) {
        case '1':
          this.paymentForm.get('paymentAmountType').patchValue('balance');
          break;
        case '2':
          this.paymentForm.get('paymentAmountType').patchValue('minimum');
          break;
        case '3':
          this.paymentForm.get('paymentAmountType').patchValue('other');
          if (Number(amount)) {
            this.paymentForm.get('paymentAmount').patchValue(amount);
          }
          break;
        default:
          this.paymentForm.get('paymentAmountType').patchValue('minimum');
      }
    });
    this.paymentForm.valueChanges.subscribe(e => {
      this.submitted = false;
    });

    const lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
    const productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);

    this.payInFullFlag = (lob == 'HO' || productCode == 'PCAT' || productCode == 'ALN_UMB') ? false : true;

    this.paymentProvider.getPaymentDetails(this.policyNumber).subscribe(val => {
      this.billingDetails = val.billingDetails;
      this.policyDetails = val.policyDetails.product;
      this.addressDetails = val.policyDetails.primaryAddress;
      //this.underwriterCode = val.policySearchInfo.underwriterCode;
      this.underwriterCode = "ALN_UND";
      this.balanceDue = this.getBalanceDue();
      this.coverageThrough = this.getBalanceTillDt();
      this.populatePaymentAmount();
      this.hydratePaymentForm(this.policyNumber);
      this.paymentDetails = {
        "amountDue": this.billingDetails.nextPaymentAmnt,
        "paymentDueDt": this.billingDetails.nextPaymentDueDt,
        "landingPage": true,
        "lastPaymentDate": this.billingDetails.lastPaymentDt,
        "pendingCancellation": this.policyDetails.cancelPending === "Yes" ? true : false
      }
      sessionStorage.setItem(CURRENT_POLICY_PAY_PLAN, this.billingDetails.payplan);


      if (this.contains(validStatus, this.policyDetails["cancelPolicyStatus"])) {
        this.showPaymentImpMsg = true;
      }

      if (sessionStorage.getItem("showPayImpMsg")) {
        this.showPaymentImpMsg = false;
      }
    });

    this.storedCreditCards = [];
    this.storedBankAccounts = [];
    this.storedWalletMap = {};
    let app = sessionStorage.getItem(PAYMENT_SOURCE);
    app = (app) ? app : WALLET_APP_NAME;
    let isQPayAdmin = sessionStorage.getItem(ADMIN_QPAY_TOKEN); //QPtrue
    this.paymentSavingAllowed = (isQPayAdmin === 'QPtrue') || (isQPayAdmin === String(true)) || app === WALLET_APP_NAME;

    this.hydrateFormObservable.subscribe(isFormHydrated => {
      if (isFormHydrated) {
        this.autoSelectForm(this.paymentD);
        this.managePaymentsProviderService.getPolicyPaymentDetails(
          sessionStorage.getItem(USER_ID), this.policyNumber, app).subscribe(wallets => {
            if (wallets) {
              wallets.forEach((wallet: any) => {
                this.storedWalletMap[wallet.walletId] = wallet;

                if (wallet.paymentType === "CC" || wallet.paymentType === "ATM") {
                  wallet.selected = this.paymentForm.value.ccSavedPaymentMethod === wallet.walletId;
                  this.storedCreditCards.push(wallet);
                  if (wallet.defaultInd === "Yes" || wallet.selected) {
                    this.defaultCreditCardWallet = wallet;
                  }
                }
                else if (wallet.paymentType === "ACH") {
                  wallet.selected = this.paymentForm.value.eftSavedPaymentMethod === wallet.walletId;
                  this.storedBankAccounts.push(wallet);
                  if (wallet.defaultInd === "Yes" || wallet.selected) {
                    this.defaultBankAccountWallet = wallet;
                  }
                }
              });
            
            }

              // NO NOIC

              // If any Default Bank Account - EFT
              // If any Default Card - CC
              // If any Saved Bank Account - EFT
              // If any Saved Card - CC
              // ELSE
              // Default to Bank

              // NOIC

              // If any Default Card - CC
              // If any Default Bank Account - EFT
              // If any Saved Card - CC
              // If any Saved Bank Account - EFT
              // ELSE
              // Default to Card


              if (this.paymentDetails.pendingCancellation) {
                if (this.defaultCreditCardWallet) {
                  this.onTogglePaymentMethod('cc');
                  this.savedPaymentMethodChanged({ 'key': 'ccSavedPaymentMethod', 'value': this.defaultCreditCardWallet.walletId }, 'cc');
                } else if (this.defaultBankAccountWallet) {
                  this.onTogglePaymentMethod('eft');
                  this.savedPaymentMethodChanged({ 'key': 'eftSavedPaymentMethod', 'value': this.defaultBankAccountWallet.walletId }, 'eft');
                } else if (wallets && wallets.some(wallet => wallet.paymentType === "CC" || wallet.paymentType === "ATM")) {
                  this.onTogglePaymentMethod('cc');
                } else if (wallets && wallets.some(wallet => wallet.paymentType === "ACH")) {
                  this.onTogglePaymentMethod('eft');
                } else {
                  this.onTogglePaymentMethod('cc');
                }
              } else {
                if (this.defaultBankAccountWallet) {
                  this.onTogglePaymentMethod('eft');
                  this.savedPaymentMethodChanged({ 'key': 'eftSavedPaymentMethod', 'value': this.defaultBankAccountWallet.walletId }, 'eft');
                } else if (this.defaultCreditCardWallet) {
                  this.onTogglePaymentMethod('cc');
                  this.savedPaymentMethodChanged({ 'key': 'ccSavedPaymentMethod', 'value': this.defaultCreditCardWallet.walletId }, 'cc');
                } else if (wallets && wallets.some(wallet => wallet.paymentType === "ACH")) {
                  this.onTogglePaymentMethod('eft');
                } else if (wallets && wallets.some(wallet => wallet.paymentType === "CC" || wallet.paymentType === "ATM")) {
                  this.onTogglePaymentMethod('cc');
                } else {
                  this.onTogglePaymentMethod('eft');
                }
              }


            const cardNumber = this.paymentForm.get('cardForm').get('cardNumber').value;
            if (cardNumber && cardNumber.length > 0) {
              this.paymentForm.markAllAsTouched();
            }
            const bankAccountNumber = this.paymentForm.get('eftForm').get('accountNumber').value;
            if (bankAccountNumber && bankAccountNumber.length > 0) {
              this.paymentForm.markAllAsTouched();
            }

            if (this.paymentProvider.getACHFailureCount() > 2) {
              this.paymentForm.get('eftForm').disable();
              this.paymentForm.get('paymentOptionSelected').setValue('');
              this.paymentMethod['eft'] = false;
              this.disableEFT = true;
            }
            // this.scrollToInvalidService.scrollToFirstInvalidControl(this.paymentFormRef);
            this.showSpinner = false;
          },
            error => {
              console.log(error);
              this.showSpinner = false;
            }
          );
      }
    });
    this.updateSafeToNavigate(false);
  }

  populatePaymentAmount() {
    const paymentAmtType = this.paymentForm.get('paymentAmountType').value;
    let amount = 0;
    switch (paymentAmtType) {
      case 'balance':
        amount = this.getBalanceDue();
        break;
      case 'minimum':
        amount = this.billingDetails.nextPaymentAmnt;
        break;
    }
    if (Number(amount)) {
      this.paymentForm.get('paymentAmount').patchValue(amount);
    }
  }

  setupPaymentForms() {
    const eftForm: any = this.paymentForm.get('eftForm');
    const cardForm: any = this.paymentForm.get('cardForm');
    if (!eftForm.get('accountHolderName')) {
      eftForm.addControl(
        'accountHolderName',
        this.fb.control('', [Validators.required])
      );
    }
    if (!eftForm.get('accountEmailId')) {
      eftForm.addControl(
        'accountEmailId',
        this.fb.control('', [Validators.required, Validators.email])
      );
    }
    if (!cardForm.get('cardHolderName')) {
      cardForm.addControl(
        'cardHolderName',
        this.fb.control('', [Validators.required])
      );
    }
    if (!cardForm.get('cardHolderEmailId')) {
      cardForm.addControl(
        'cardHolderEmailId',
        this.fb.control('', [Validators.required, Validators.email])
      );
    }
    if (!cardForm.get('securityCode')) {
      cardForm.addControl(
        'securityCode',
        this.fb.control('', [Validators.required, Validators.minLength(3)])
      );
    }
    if (!cardForm.get('maskedSecurityCode')) {
      cardForm.addControl(
        'maskedSecurityCode',
        this.fb.control('', [Validators.required, Validators.minLength(3)])
      );
    }
  }

  hydratePaymentForm(policyNumber: string) {
    const paymentD = this.paymentProvider.getPolicyPaymentDetails();
    const insuredName = this.billingDetails.insuredName;
    if (!paymentD.paymentDetails) {
      this.paymentProvider.getPaymentDetailsFromSession(policyNumber).subscribe(
        paymentData => {
          this.fillUserInformation(insuredName);
          if (paymentData) {
            this.paymentForm.patchValue({
              ...paymentData
            });
            this.paymentMethod[paymentData.paymentOptionSelected] = true;
            paymentD.paymentDetails = paymentData;
          } else {
            //this.fillUserInformation(insuredName);
          }
          this.onPaymentOptionSelected();
          this.isPaymentDetailsLoaded = true;
          this.hydrateFormSubject$.next(true);
        },
        () => {
          this.fillUserInformation(insuredName);
          this.hydrateFormSubject$.next(true);
        }
      );
    } else {
      this.paymentForm.patchValue({
        ...paymentD.paymentDetails
      });
      this.paymentMethod[paymentD.paymentDetails.paymentOptionSelected] = true;
      this.onPaymentOptionSelected();
      this.isPaymentDetailsLoaded = true;
      this.hydrateFormSubject$.next(true);
    }
    this.paymentForm.updateValueAndValidity();
    this.paymentD = paymentD;
  }

  fillUserInformation(nameInsured) {
    nameInsured = nameInsured || this.billingDetails.insuredName;
    this.paymentForm.patchValue({
      eftForm: {
        accountHolderName: nameInsured,
        accountEmailId: sessionStorage.getItem(USER_ID)
      },
      cardForm: {
        cardHolderName: nameInsured,
        cardHolderEmailId: sessionStorage.getItem(USER_ID)
      }
    });
  }

  startNavigationFlow() {
    this.paymentProvider
      .encryptPaymentDetails(
        this.paymentForm.value,
        this.policyDetails.policyNumber
      )
      .subscribe(data => this.savePdAndNavigate(data));
  }

  savePdAndNavigate(response) {
    const { pd } = response;
    if (pd) {
      sessionStorage.setItem(QPAY_PAYMENT_DETAILS, pd);
    }
    this.navigateToReview();
  }

  navigateToReview() {
    this.paymentProvider.setPaymentDetails(this.paymentForm.value);
    this.router.navigate(['../review'], {
      queryParams: this.route.snapshot.queryParams,
      relativeTo: this.route
    });
  }

  getBalanceDue(): number {
    return this.billingDetails.payInFullFlag &&
      Number(this.billingDetails.billedUnpaid) > 0
      ? Number(this.billingDetails.payInFullAmnt)
      : Number(this.billingDetails.balance);
  }

  getBalanceTillDt(): string {
    const { cancelPolicyStatus } = this.policyDetails;
    return cancelPolicyStatus && 'cancel' === cancelPolicyStatus.toLowerCase()
      ? this.policyDetails.cancelEffectiveDate
      : this.billingDetails.expiryDate;
  }

  onPaymentAmountSelected(type) {
    if (type === 'minimum') {
      this.paymentForm
        .get('paymentAmount')
        .setValue(this.billingDetails.nextPaymentAmnt);
    } else if (type === 'balance') {
      this.paymentForm.get('paymentAmount').setValue(this.balanceDue);
    } else if (type === 'pif') {
      this.paymentForm.get('paymentAmount').setValue(this.billingDetails.payInFullAmnt);
    } else {
      this.paymentForm.get('paymentAmount').setValue(0);
    }
    this.paymentForm.get('paymentAmount').markAsTouched();
  }

  onPaymentOptionSelected() {
    this.paymentForm.get('cardForm').disable();
    this.paymentForm.get('cardForm').updateValueAndValidity();
    this.paymentForm.get('eftForm').disable();
    this.paymentForm.get('eftForm').updateValueAndValidity();
  }

  paymentAmountValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value > this.balanceDue ? { amountGreater: true } : null;
    };
  }

  onTogglePaymentMethod(method) {
    const flag = !this.paymentMethod[method];
    this.paymentMethod = {
      eft: false,
      cc: false,
      [method]: flag
    };
    this.storedBankAccounts.forEach(bank => delete bank['selected']);
    this.storedCreditCards.forEach(card => delete card['selected']);
    this.paymentForm.get('paymentOptionSelected').setValue('');
    if (method === 'eft') {
      if (flag) {
        this.paymentForm.get('paymentOptionSelected').setValue('eft');
      }
      this.enableBankForm();
    } else if (method === 'cc') {
      if (flag) {
        this.paymentForm.get('paymentOptionSelected').setValue('cc');
      }
      this.enableCardForm();
    }
  }

  onContinue() {
    if (this.paymentForm.valid) {
      if (this.policyDetails.cancelPending !== "Yes" && SCHEDULED_PAY_PLANS.includes(this.billingDetails.payplan)) {
        this.areYouSurePopup = true;
      } else {
        this.doContinue();
      }
    } else {
      this.formValidator.validateAllFormFields(this.paymentForm);
    }
  }

  doContinue() {
    this.submitted = true;
    this.updateSafeToNavigate(true);
    this.isReviewInProgress = true;
    merge(this.cardEntry$, this.savedCardEntry$, this.electronicEntry$, this.savedBankEntry$, this.invalidEntry$).subscribe(
      () => (this.isReviewInProgress = false)
    );
  }

  doNeverMind() {
    const app = sessionStorage.getItem(PAYMENT_SOURCE);
    if (!app || app.toLowerCase() === 'eservice') {
      this.isSafeToNavigate = true;
      this.router.navigate(['/eservice/home'], { relativeTo: this.route });
    } else {
      this.areYouSurePopup = false;
    }
  }

  onCancel() {
    sessionStorage.removeItem(QPAY_PAYMENT_DETAILS);
  }

  showHelpText(key) {
    this.helpFlags = { ... this.helps };
    this.helpFlags[key] = true;
  }

  hideHelpText() {
    this.helpFlags = { ... this.helps };
  }

  closeImportantMessage() {
    sessionStorage.setItem("showPayImpMsg", "Y");
    this.showPaymentImpMsg = false;
  }

  private contains(a, obj) {
    for (var i = 0; i < a.length; i++) {
      if (a[i] === obj) {
        return true;
      }
    }
    return false;
  }

  onAddNewCard() {
    this.resetAllCardForm();
    this.disableAllCardForm();
    this.paymentForm.get('cardForm').enable();
    this.paymentForm.get('saveThisCard').enable();
    this.paymentForm.get('cardForm').updateValueAndValidity();
    this.addNewCard = true;
    this.fillUserInformation(null);


  }

  onAddNewBank() {
    this.resetAllBankForm();
    this.disableAllBankForm();
    this.paymentForm.get('eftForm').enable();
    this.paymentForm.get('saveThisBank').enable();
    this.paymentForm.get('eftForm').updateValueAndValidity();
    this.addNewBank = true;
    this.fillUserInformation(null);

  }

  savedPaymentMethodChanged(item: any, paymentType: string) {
    const { key, value } = item;
    this.paymentForm.get(key).markAsTouched();
    this.paymentForm.get(key).setValue(value);

    if (paymentType === 'cc') {

      this.resetAllBankForm();
      this.disableAllBankForm();
      let wallet = this.storedWalletMap[value];
      let savedCardType = wallet.transType ? wallet.transType.toUpperCase() : '';
      this.cvvLength = (savedCardType === 'AMEX'.toUpperCase()) ? 4 : 3;
      this.paymentForm.controls['savedCardSecurityCode'].setValidators([Validators.required, Validators.minLength(this.cvvLength), Validators.maxLength(this.cvvLength)]);
      this.paymentForm.get('savedCardSecurityCode').markAsUntouched();
      this.paymentForm.get('savedCardSecurityCode').setValue(null);
      this.paymentForm.get('savedCardSecurityCode').updateValueAndValidity();
    } else if (paymentType === 'eft') {

      this.resetAllCardForm();
      this.disableAllCardForm();
    }
  }

  showHideNoteSection(event) {
    this.showNote = !event;
  }

  disableAllBankForm() {
    this.paymentForm.get('eftForm').disable();
    this.paymentForm.get('eftSavedPaymentMethod').disable();
    this.paymentForm.get('saveThisBank').disable();
  }

  resetAllBankForm() {
    this.paymentForm['eftForm'] = this.bankFormProvider.getForm();
    this.paymentForm.get('eftSavedPaymentMethod').reset();
    this.paymentForm.get('saveThisBank').reset();
    this.addNewBank = false;
  }

  enableBankForm() {
    this.resetAllCardForm();
    this.disableAllCardForm();
    if (this.storedBankAccounts.length === 0) {
      this.onAddNewBank();
    } else {
      this.enableManagedBankForm();
    }

  }

  enableManagedBankForm() {
    this.resetAllBankForm();
    this.disableAllBankForm();
    this.paymentForm.get('eftSavedPaymentMethod').enable();
    this.addNewBank = false;
  }

  disableAllCardForm() {
    this.paymentForm.get('cardForm').disable();
    this.paymentForm.get('ccSavedPaymentMethod').disable();
    this.paymentForm.get('saveThisCard').disable();
    this.paymentForm.get('savedCardSecurityCode').disable();
  }

  resetAllCardForm() {
    this.paymentForm['cardForm'] = this.cardFormProvider.getForm();
    this.paymentForm.get('ccSavedPaymentMethod').reset();
    this.paymentForm.get('saveThisCard').reset();
    this.paymentForm.get('savedCardSecurityCode').reset();
    this.addNewCard = false;
  }

  enableCardForm() {
    this.resetAllBankForm();
    this.disableAllBankForm();
    if (this.storedCreditCards.length === 0) {
      this.onAddNewCard();
    } else {
      this.enableManagedCardForm();
    }
  }

  enableManagedCardForm() {
    this.resetAllCardForm();
    this.disableAllCardForm();
    this.paymentForm.get('ccSavedPaymentMethod').enable();
    this.paymentForm.get('savedCardSecurityCode').enable();
    this.addNewCard = false;
  }

  autoSelectForm(paymentD) {

    if (paymentD) {
      if (paymentD.paymentDetails['cardForm'] && paymentD.paymentDetails['cardForm']['cardNumber']) {
        this.enableCardForm();
      } else if (paymentD.paymentDetails['ccSavedPaymentMethod']) {
        this.enableManagedCardForm();
      } else if (paymentD.paymentDetails.eftForm && paymentD.paymentDetails.eftForm.accountNumber) {
        this.enableBankForm();
      } else if (paymentD.paymentDetails['eftSavedPaymentMethod']) {
        this.enableManagedBankForm();
      }
      this.paymentForm.patchValue({
        ...paymentD.paymentDetails
      });

    }

    delete this.paymentD;
  }


  onContinueToPaymentForm() {
    this.showIntermediateRegisterationForm = false;
  }

  onRegistrationSubmitted(eventResponse) {
    if (eventResponse && eventResponse.responseCode === "REGISTRATION_SUCCESS") {
      console.log("REGISTRATION_SUCCESS");

      sessionStorage.removeItem("qPayEserviceEnroll");
      sessionStorage.removeItem(PAYMENT_SOURCE);
      this.updateSafeToNavigate(true);
      this.router.navigate(['../intermediateRegistrationComplete'], {
        queryParams: { 'sourcePage': 'QPAY_ENTRY' },
        relativeTo: this.route
      });

    } else if (eventResponse && eventResponse.responseCode === 'EXISTING') {
      sessionStorage.removeItem("qPayEserviceEnroll");
      sessionStorage.removeItem(PAYMENT_SOURCE);
      this.updateSafeToNavigate(true);
      this.router.navigate(['/eservice/login'], {
        relativeTo: this.route,
        state: {
          email: eventResponse.userName
        }
      });
    } else if (eventResponse && eventResponse.responseCode === 'NEW_POLICY') {
      sessionStorage.removeItem("qPayEserviceEnroll");
      sessionStorage.removeItem(PAYMENT_SOURCE);
      this.updateSafeToNavigate(true);
      this.router.navigate(['/eservice/link-policies'], {
        relativeTo: this.route,
        state: {
          email: eventResponse.userName
        }
      });
    } else {
      console.log(`REGISTRATION_ERROR: ${eventResponse}`);
    }
  }



}